<template>
  <!-- 抽盒商品 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <rich-text ref="rich-text" @refresh="getTableData"></rich-text>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'draws',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "rich-text": (resolve) => require(["@/views/modules/install/richText"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      butLoading: false,
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '协议名称', prop: 'notiName', align: 'left', width: '200' },
        { label: '描述', prop: 'note', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, }
          ],
        },
      ],
      tableData: [],
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/notiAgrent/findNotiAgrent', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          notiName: this.retrieveForm.searchValue,
          notiType: 2,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      })
    },
    //新增列表
    addList () {
      this.$refs['rich-text'].init(2)
    },
    //编辑列表
    editList (row) {
      this.$refs['rich-text'].init(2, row.id)
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/notiAgrent/removeNotiAgrent', { id: row.id }).then(({ data: result }) => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //表格跳转详情
    headerRowClick (row) {
      console.log(row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>